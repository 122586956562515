import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import { createI18n } from 'vue-i18n';

const messages = {
  ru: {
      message: {
          rules: 'Правила',
          rules_link: '/pdf/rules_ru.pdf',
      }
  },
  kz: {
      message: {
          rules: 'Ереже',
          rules_link: '/pdf/rules_kz.pdf',
      }
  }
};

const i18n = createI18n({
    locale: 'ru',
    messages,
  })

createApp(App)
.use(router)
.use(i18n)
.mount('#app')
