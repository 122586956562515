import { createRouter, createWebHistory } from 'vue-router'
// import WelcomePage from '../components/WelcomePage';
import WelcomeKz from '../components/WelcomeKz';
import WelcomeRu from '../components/WelcomeRu';

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: WelcomeRu
  },
  {
    path: '/kz',
    name: 'welcomeKz',
    component: WelcomeKz
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
