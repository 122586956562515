<script>

export default{
    mounted() {
        if (localStorage.getItem('lang')) {
            this.$i18n.locale = localStorage.getItem('lang');
        }
    },
    methods: {
        changeLang(l) {
            localStorage.setItem('lang', l);
            this.$i18n.locale = l;

        }
    },
}
</script>


<template>
    <div class="flex w-full relative lays-promo">
        <div class="flex gap-4 lg:gap-6 fixed top-0 items-center w-full p-2 justify-between bg-[#E41F27] max-w-[1920px] left-1/2 -translate-x-1/2">
            <div class="flex items- gap-1">
                <router-link
                    @click="changeLang('ru')"
                    to="/"
                    :class="{'text-[#E41F27]': $i18n.locale == 'ru', 'bg-[#F5C92D]': $i18n.locale == 'ru', 'text-[#F5C92D]': $i18n.locale != 'ru'}"
                    class="flex rounded-full w-[30px] h-[30px] justify-center items-center lg:w-[59px] lg:h-[59px] font-extrabold lg:text-[32px]">
                        RU
                </router-link>
                <router-link
                    @click="changeLang('kz')"
                    to="/kz"
                    :class="{'text-[#E41F27]': $i18n.locale == 'kz', 'bg-[#F5C92D]': $i18n.locale == 'kz', 'text-[#F5C92D]': $i18n.locale != 'kz'}"
                    class="flex rounded-full w-[30px] h-[30px] justify-center items-center lg:w-[59px] lg:h-[59px] font-extrabold lg:text-[32px]">
                        KZ
                </router-link>
            </div>
            <a
            :href="$t('message.rules_link')"    
            target="_blank"
            class="px-4 py-2 rounded-[50px]  text-[#E41F27] text-[16px] font-normal bg-[#F5C92D] transition-all lg:px-[48px] lg:py-[14px] lg:text-[36px]">
                {{ $t('message.rules') }}
        </a>
        </div>
        <router-view/>
    </div>
</template>

<style>
@import url('https://fonts.cdnfonts.com/css/raleway-5');
.lays-promo a{
    /* font-family: Raleway; */
}
.lays-promo > a:hover{
    transform: scale(1.1);
    @apply shadow-md;
}

.lays-promo > img{
    @apply max-w-full mt-[30px];
}
</style>